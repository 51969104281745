export {}

declare global {
  interface Window {
    airgap: any
    transcend: any
  }
}

// Configure "Your Privacy Choices" link in the footer
window.addEventListener('turbolinks:load', function() {
  function configurePrivacyFooter(showYourPrivacyChoices, yourPrivacyChoicesclickHandler) {
    const element = document.querySelector(".privacy-and-terms-of-use");
    if (element) {
      const privacyClassName = showYourPrivacyChoices ? "with-privacy-choices" : "without-privacy-choices";
      element.classList.add(privacyClassName);

      if (showYourPrivacyChoices) {
        // Attach event handler to Privacy Choices link(s)
        const links = document.querySelectorAll(".your-privacy-choices .trix-link");
        links.forEach((link) => {
          link.addEventListener("click", yourPrivacyChoicesclickHandler, false);
        });
      }
    }
  }

  if (typeof window.airgap !== "undefined" && window.airgap && window.airgap.loadOptions &&
      window.airgap.loadOptions.reportOnly === "off") {
    const { consentManagerConfig } = window.airgap.loadOptions;

    // Determine Consent Management UI View State to use
    const currentRegime = window.airgap.getRegimes().values().next().value;
    let uiViewState = consentManagerConfig.initialViewStateByPrivacyRegime[currentRegime];
    if (uiViewState === "Hidden" && currentRegime === "us-do-not-sell") {
      uiViewState = "DoNotSellExplainer";
    }

    // Event handler
    const showConsentManager = function (event) {
      event.preventDefault();
      window.transcend.showConsentManager({ viewState: uiViewState });
    }

    // Determine if "Your Privacy Choices" link should be shown in the footer
    configurePrivacyFooter(uiViewState !== "Hidden", showConsentManager);
  } else {
    configurePrivacyFooter(false, null);
  }
});
